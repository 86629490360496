import * as React from 'react';
import styles from './EmptyState.scss';
import darkPlayer from './SpotifyCompactDark.png';
import darkPlayerCenter from './SpotifyCompactDarkRepeater.png';

export interface IProps {}

export const EmptyState: React.FunctionComponent<IProps> = () => {
  const withBg = (img: any) => ({
    backgroundImage: `url('${img}')`,
  });
  return (
    <div className={styles.emptyState} data-hook="empty-state">
      <div className={styles.leftPart} style={withBg(darkPlayer)} />
      <div className={styles.centerPart} style={withBg(darkPlayerCenter)} />
      <div className={styles.rightPart} style={withBg(darkPlayer)} />
    </div>
  );
};

EmptyState.displayName = 'EmptyState';
