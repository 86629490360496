import _ from 'lodash';

export const onWindowResize = _.debounce(() => {
  window.Wix.getBoundingRectAndOffsets(({ rect }) => {
    setIframeSize(rect.height, Number(rect.width));
  });
}, 400);

function setIframeSize(height: number, width: number) {
  const newHeight = Math.max(80, height);
  const newWidth = Math.max(230, width);

  if (height !== newHeight || width !== newWidth) {
    window.Wix.resizeComponent({
      height: newHeight,
      width: newWidth,
    });
  }
}
